import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    chosen: PropTypes.array,
    format: PropTypes.any,
    multiple: PropTypes.any,
    records: PropTypes.array,
    previewing: PropTypes.object,
    onChooseRecord: PropTypes.func,
    onPreview: PropTypes.func
  }

  render() {
    const { format, multiple, records } = this.props
    return (
      <div className="maha-entity-chooser-results">
        { records.map((record, index) => (
          <div className={ this._getClass(record) } onClick={ this._handleSelect.bind(this, record) } key={`record_${index}`}>
            { multiple &&
              <div className="maha-entity-chooser-result-toggle">
                <Icon icon={ this._getIcon(record) } onClick={ this._handleChoose.bind(this, record) }  />
              </div>          
            }
            <div className="maha-entity-chooser-result-details">
              <Format { ...record } format={ format } value={ record } />
            </div>
          </div>
        ))}
      </div>
    )    
  }

  _getClass(record) {
    const classes = ['maha-entity-chooser-result']
    const { previewing } = this.props
    if(record.id === previewing?.id) classes.push('selected')
    return classes.join(' ')
  }

  _getIcon(record) {
    const { chosen } = this.props
    const is_selected = chosen.find(item => item.id === record.id) !== undefined
    return is_selected ? 'check-square' : 'square-o'
  }

  _handleChoose(record, e) {
    this.props.onChooseRecord(record)
  }

  _handleSelect(record, e) {
    const { multiple } = this.props
    if(!multiple) this.props.onChooseRecord(record)
    this.props.onPreview(record)
  }

}

export default Results
