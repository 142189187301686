import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import Panel from '@admin/components/panel'
import Import from './import'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/agency/email_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      { label: 't(Publishings)', key: 'publishings_count', collapsing: true, align: 'right' }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['american english','canadian english','french','german','italian'] }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any emails)',
      buttons: [
        { label: 't(Create Email Campaign)', modal: New }
      ]
    },
    entity: 'email campaign',
    recordTasks: (record) => [
      {
        label: 't(Clone Email Campaign)',
        modal: <Clone email_campaign={ record } />
      },
      {
        label: 't(Delete Email Campaign)',
        show: record.status !== 'published' || record.advisor_email_campaigns_count === 0,
        confirm: 't(Are you sure you want to delete this campaign? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete campaign)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/email_campaigns/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      {
        label: 't(Create Email Campaign)', 
        modal: New
      }, {
        label: 't(Import Email Campaigns)', 
        modal: Import
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
