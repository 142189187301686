import EntityChooser from '@admin/components/entity_chooser'
import ContentToken from '@apps/maha/admin/tokens/content'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'

class NewModule extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <EntityChooser { ...this._getChooser() } />
  }

  _getChooser() {
    return {
      endpoint: '/api/admin/content/email_modules/library',
      entity: 'module',
      format: (content) => (
        <ContentToken content={ content } />
      ),
      multiple: false,
      preview: (entity) => `maha_contents/${entity.code}`,
      onCancel: this._handleCancel,
      onDone: this._handleSubmit
    }
  }

  _getUnique(config) {
    return {
      ...config,
      id: getCode(10),
      content: {
        ...config.content,
        ...config.content.children ? {
          children: config.content.children.map(child => {
            return this._getUnique(child)
          })
        } : {}
      }
    } 
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSubmit(module) {
    this.props.onDone({
      ...this._getUnique(module.config.entity.content.children[0]),
      meta: {
        label: module.title
      }
    })
    this.context.modal.close()
  }

}

export default NewModule
