import SchedulingStrategyToken from '@apps/campaigns/admin/tokens/scheduling_strategy'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Voice)', type: 'segment', fields: [
              { type: 'radiogroup', name: 'voice', options: [
                { value: 'none', text: 't(Dont change the voice)' },
                { value: 'translate', text: 't(Translate the text to another voice)' }
              ], defaultValue: 'none' },
              { label: 'Perspective', type: 'dropdown', show: config.voice === 'translate', name: 'perspective', options: [
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], defaultValue: 'first-person singular' },
              { label: 'Language', type: 'dropdown', show: config.voice === 'translate', name: 'language', options: [
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'american english' }
            ] },
            { label: 't(Status)', name: 'status', type: 'radiogroup', options: [
              { value: 'published', text: 't(Published)' },
              { value: 'draft', text: 't(Draft)' }
            ], defaultValue: 'published' }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(social_campaign) {
    this.props.onSave(social_campaign)
  }

}

export default Details
